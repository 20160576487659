import React from 'react';
import { useRef } from 'react';
import './contact.css';
import {AiOutlineMail , AiOutlineCopy } from 'react-icons/ai';
import{BsWhatsapp} from 'react-icons/bs';
import emailjs from '@emailjs/browser';
import { useTranslation } from "react-i18next";

const mail = 'gcarreiras86@gmail.com'
const cel = 5492216191484

export const Contact = () => {
  
  const [text] = useTranslation('global')
  
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_fs0kglr', 'template_zvcbybq', form.current, 'aNUUtS258rFOu1rUE')
      .then((result) => {
          alert(`${text("contact.msj")}`);
          e.target.reset()
      }, (error) => {
          alert(`${text("contact.msjProblem")}`);
      });
  };
  return (
    <section id='contact'>
      <h5>{text("contact.getTouch")}</h5>
      <h2>{text("contact.contactMe")}</h2>
      
      <div className='container contact__container'>
        <div className='contact__options'>
          <article className='contact__option'>
            <AiOutlineMail className='contact__option-icon'/>
            <h4>Email</h4>
            <h5 >gcarreiras86@gmail.com</h5>
            <a target='_blank' href='mailto:gcarreiras86@gmail.com'>{text("contact.sendMsj")}</a>
            <p/>   
            <a onClick={() => navigator.clipboard.writeText(mail )}>{text("contact.copyMail")}</a>
          </article>

          <article className='contact__option'>
            <BsWhatsapp className='contact__option-icon'/>
            <h4>WhatsApp</h4>
            <h5 >+5492216191484</h5>
            <a target='_blank' href='https://api.whatsapp.com/send?phone=5492216191484'>{text("contact.sendWhatsapp")}</a>   
            <p/> 
            <a onClick={() => navigator.clipboard.writeText(cel)}>{text("contact.copyCel")}</a>
          </article>

        </div>

        <form ref={form} onSubmit={sendEmail}>
          <input type="text" name='name' placeholder={text("contact.placeName")} required/>
          <input type='email' name='email' placeholder={text("contact.placeMail")} required/>
          <textarea name='message' rows='7' placeholder={text("contact.placeMsj")} required/>
          <button type='submit' className='btn btn-primary'>{text("contact.btnSend")}</button>
        </form>
      </div>
    </section>
  )
}
