import React from 'react';
import{AiOutlineHome , AiOutlineUser , AiOutlineMessage} from 'react-icons/ai'
import {BsBook} from 'react-icons/bs';
//import {MdMiscellaneousServices} from 'react-icons/md';
import {HiOutlineBriefcase} from 'react-icons/hi';
import { useState } from 'react';
import './nav.css';


const Nav = () => {

  const [activeNav, setActiveNav] = useState('#')
  
  return (
    <nav>
      <a href='#'onClick={()=>setActiveNav('#')} className={activeNav==='#' ? 'active' : ''}><AiOutlineHome/></a>
      <a href='#about'onClick={()=>setActiveNav('#about')} className={activeNav==='#about' ? 'active' : ''}><AiOutlineUser/></a>
      <a href='#experience'onClick={()=>setActiveNav('#experience')} className={activeNav==='#experience' ? 'active' : ''}><BsBook/></a>
      {/* <a href='#service' onClick={()=>setActiveNav('#service')} className={activeNav==='#service' ? 'active' : ''}><MdMiscellaneousServices/></a> */}
      <a href='#portafolio' onClick={()=>setActiveNav('#portafolio')} className={activeNav==='#portafolio' ? 'active' : ''}><HiOutlineBriefcase/></a>
      <a href='#contact' onClick={()=>setActiveNav('#contact')} className={activeNav==='#contact' ? 'active' : ''}><AiOutlineMessage/></a>
    </nav>
  )
}

export default Nav