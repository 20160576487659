import React from 'react';
import './footer.css';
import {BsFacebook , BsInstagram} from 'react-icons/bs'
import {FaXTwitter} from 'react-icons/fa6';
import { useTranslation } from "react-i18next";

const Footer = () => {
  const [ text ] = useTranslation('global')
  return (
    <footer>
      <a href='#' className='footer__logo' >GCARREIRAS</a>
      <ul className='permalinks'>
        <li><a href="#">{text("footer.home")}</a></li>
        <li><a href="#about">{text("footer.about")}</a></li>
        <li><a href="#experience">{text("footer.experience")}</a></li>
        <li><a href="#portafolio">{text("footer.portafolio")}</a></li>
        <li><a href="#testimonials">{text("footer.testimonials")}</a></li>
        <li><a href="#contact">{text("footer.contact")}</a></li>
      </ul>
      
      <div className='footer__socials'> 
        <a href='https://www.instagram.com/gaston_carreiras/' target='_blank'><BsInstagram/></a>
        <a href='https://www.facebook.com/gastonariel.carreiras' target='_blank'><BsFacebook/></a>
        <a href='https://twitter.com/GastonCarreiras' target='_blank'><FaXTwitter/></a>
      </div>
      <div className='footer__copyright'>
        <small>By GCarriras</small>
      </div>
    </footer>
  )
}

export default Footer