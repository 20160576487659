import React from 'react';
import './testimonials.css'
import avatar1 from '../../assets/avatar1.jpg';
import avatar2 from '../../assets/darth.jpg';
import avatar3 from '../../assets/sheldon.jpg';
import { useTranslation } from "react-i18next";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/scrollbar";

//import "./styles.css";

// import required modules
import { Scrollbar } from "swiper";

const data = [
  {
    avatar : avatar2,
    name:  'Darth Vader',
    review: 'The force is strong with this one',
    index: 1
  },
  {
    avatar : avatar3,
    name:  'Dr. Sheldon Cooper',
    review: 'A neutron walks into a bar ans asks how much for a drink. The barman replies for you no charge.',
    index: 2
  },
  
  {
    avatar : avatar1,
    name:  'Gandalf the Grey',
    review: 'Fantastic reviews are coming',
    index: 3
  }
]
const Testimonials = () => {

  const [text] = useTranslation('global')

  return (
    <>
    <section id='Testimonials'>
      <h5>{text("testimonials.review")}</h5>
      <h2>{text("testimonials.test")}</h2>

      <div className='container testimonials__container'>

    <Swiper
        scrollbar={{
          hide: true,
        }}
        modules={[Scrollbar]}
        className="mySwiper"
      >
        {
          data.map(({avatar, name , review , index}) =>{
            return(
              <SwiperSlide key={index} className='testimonial'>
                <div className='client__slide'>
                  <small>Slide --> </small>
                </div>
                <div className='client__avatar'>
                  <img src={avatar} alt={name} />
                </div>
                <h5 className='client__name'>{name}</h5>
                <small className='client__review'>{text(`testimonials.${name}`)}</small>
              </SwiperSlide>
            )
            
          })
        }
  </Swiper>    
        
        
      </div>

    </section>
  </>
  )
}

export default Testimonials