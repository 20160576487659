import React from "react";
import "./experience.css";
import { BsPatchCheck } from "react-icons/bs";
import { useTranslation } from "react-i18next";

export const Experience = () => {
  const [text, i18n] = useTranslation("global");
  return (
    <section id="experience">
      <h5>{text("experience.skills")}</h5>
      <h2>{text("experience.exp")}</h2>

      <div className="container experience__container">
        <div className="experience__frontend">
          <h3>Frontend Development</h3>
          <div className="experience__content">
            <article className="experience__details">
              <BsPatchCheck className="experience__details-icon" />
              <div>
                <h4>HTML</h4>
                <small className="text-light">
                  {text("experience.experienced")}
                </small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheck className="experience__details-icon" />
              <div>
                <h4>JavaScript</h4>
                <small className="text-light">
                  {text("experience.experienced")}
                </small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheck className="experience__details-icon" />
              <div>
                <h4>React</h4>
                <small className="text-light">
                  {text("experience.experienced")}
                </small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheck className="experience__details-icon" />
              <div>
                <h4>CSS</h4>
                <small className="text-light">
                  {text("experience.experienced")}
                </small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheck className="experience__details-icon" />
              <div>
                <h4>Material UI</h4>
                <small className="text-light">
                  {text("experience.experienced")}
                </small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheck className="experience__details-icon" />
              <div>
                <h4>Vue.js</h4>
                <small className="text-light">
                  {text("experience.intermediate")}
                </small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheck className="experience__details-icon" />
              <div>
                <h4>TailwindCSS</h4>
                <small className="text-light">
                  {text("experience.intermediate")}
                </small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheck className="experience__details-icon" />
              <div>
                <h4>Bootstrap</h4>
                <small className="text-light">
                  {text("experience.intermediate")}
                </small>
              </div>
            </article>
          </div>
        </div>

        <div className="experience__backend">
          <h3>Backend Development</h3>
          <div className="experience__content">
            <article className="experience__details">
              <BsPatchCheck className="experience__details-icon" />
              <div>
                <h4>Node Js</h4>
                <small className="text-light">
                  {text("experience.experienced")}
                </small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheck className="experience__details-icon" />
              <div>
                <h4>SQL</h4>
                <small className="text-light">
                  {text("experience.experienced")}
                </small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheck className="experience__details-icon" />
              <div>
                <h4>Sequelize</h4>
                <small className="text-light">
                  {text("experience.experienced")}
                </small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheck className="experience__details-icon" />
              <div>
                <h4>Moongose</h4>
                <small className="text-light">
                  {text("experience.experienced")}
                </small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheck className="experience__details-icon" />
              <div>
                <h4>MongoDB</h4>
                <small className="text-light">
                  {text("experience.experienced")}
                </small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheck className="experience__details-icon" />
              <div>
                <h4>TypeScript</h4>
                <small className="text-light">
                  {text("experience.intermediate")}
                </small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheck className="experience__details-icon" />
              <div>
                <h4>Django</h4>
                <small className="text-light">
                  {text("experience.intermediate")}
                </small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheck className="experience__details-icon" />
              <div>
                <h4>Pyhton</h4>
                <small className="text-light">
                  {text("experience.intermediate")}
                </small>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
};
