import React from "react";
import "./portafolio.css";
import IMG1 from "../../assets/tresbien1.png";
import CANOA from "../../assets/canoaQuebrada.png";
import CAPACITAR from "../../assets/Capacitar.png";
import ACADEMIA from "../../assets/Academia.png";
import TODODEAUTOS from "../../assets/tododeautos.png";
import TORTONE from "../../assets/tortoneAutomotores.png";
import { useTranslation } from "react-i18next";

const data = [
  {
    id: 1,
    img: TORTONE,
    title: "Tortone Automotores",
    github: "https://github.com/gcarreiras",
    demo: "https://tortoneautomotores.com/",
  },
  {
    id: 2,
    img: TODODEAUTOS,
    title: "TodoDeAutos.com",
    github: "https://github.com/gcarreiras",
    demo: "https://tododeautos.com/",
  },
  {
    id: 3,
    img: CAPACITAR,
    title: "Capacitar Digital",
    github: "https://github.com/gcarreiras",
    demo: "https://capacitar.digital/",
  },
  {
    id: 4,
    img: CANOA,
    title: "Pousada Canoa Quebrada",
    github: "https://github.com/gcarreiras",
    demo: "https://pousadaterranova.com/",
  },
  {
    id: 5,
    img: ACADEMIA,
    title: "Academia Hispanoamericana de Buenas Letras",
    github: "https://github.com/gcarreiras",
    demo: "https://hispanoamericanadebuenasletras.org/",
  },
  {
    id: 6,
    img: IMG1,
    title: "Tres Bien E-commerce",
    github: "https://github.com/PF-eCommerce",
    demo: "https://www.youtube.com/watch?v=J6XvWayqU7Q&t=28s",
  },
];

const Portafolio = () => {
  const [text, i18n] = useTranslation("global");
  return (
    <section id="portafolio">
      <h5>{text("portafolio.work")}</h5>
      <h2>Portafolio</h2>

      <div className="container portafolio__container">
        {data.map(({ id, img, title, github, demo }) => {
          return (
            <article key={id} className="portafolio__item">
              <div className="portafolio__item-image">
                <img src={img} alt={title} />
              </div>
              <h3>{title}</h3>
              <div className="portafolio__item-cta">
                <a href={github} className="btn" target="_blank">
                  Github
                </a>
                <a href={demo} className="btn btn-primary" target="_blank">
                  Link
                </a>
              </div>
            </article>
          );
        })}
      </div>
    </section>
  );
};

export default Portafolio;
