import React from 'react';
import{BsLinkedin , BsGithub , BsInstagram} from 'react-icons/bs';
const HeaderSocials = () => {
  return (
    <div className='header__socials'>
        <a href='https://www.linkedin.com/in/gaston-carreiras/' target='blank'>
          <BsLinkedin/>
        </a>
        <a href='https://github.com/gcarreiras' target='blank'>
          <BsGithub/>
        </a>
        <a href='https://www.instagram.com/gaston_carreiras/' target='blank'>
          <BsInstagram/>
        </a>

    </div>
  )
}

export default HeaderSocials