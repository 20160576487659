import React from "react";
import "./about.css";
//deberia importar alguna foto decente por ahora uso la de slack
import { FaAward } from "react-icons/fa";
import { HiUsers } from "react-icons/hi";
import{AiOutlineFundProjectionScreen} from 'react-icons/ai';
import { useTranslation } from "react-i18next";

export const About = () => {
  const [ text ] = useTranslation('global')
  return (
    <section id="about">
      <p className="parrafo"> </p>
      <h5 className="title__about">{text("about.getToKnow")}</h5>
      <h2>{text("about.aboutMe")}</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img
              src="https://ca.slack-edge.com/TPRS7H4PN-U02KPNS6KEZ-7f4655eed836-512"
              alt="About Image"
            />
          </div>
        </div>
        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              <FaAward className="about__icon" />
              <h5>{text("about.experience")}</h5>
              <small>{text("about.time")}</small>
            </article>

            <article className="about__card">
              <HiUsers className="about__icon" />
              <h5>{text("about.clients")}</h5>
              <small>{text("about.cant")}</small>
            </article>

            <article className="about__card">
              <AiOutlineFundProjectionScreen className="about__icon" />
              <h5>{text("about.project")}</h5>
              <small>{text("about.pCant")}</small>
            </article>

        </div>
            
          <p>
          {text("about.description")}
          </p>
          <a href="#contact" className="btn btn-primary">{text("about.talk")}</a>
          
          </div>
      </div>
    </section>
  );
};
