import React from 'react';
import CTA from './CTA'
import './Header.css';
import ME from '../../assets/prueba 1.png';
import ME1 from '../../assets/imagenOpcion1.png'
import HeaderSocials from './HeaderSocials';
import { useTranslation } from "react-i18next";
import { useState } from 'react';
import CVen from '../../assets/CV Gaston Carreiras English.pdf';
import CVes from '../../assets/CV Gaston Carreiras Español.pdf';


const Header = () => {
  const [ text  , i18n] = useTranslation('global')
  const [dataEn, setData] = useState(true);
  const [selectCV , setCV] = useState(CVen);
  

  function changeLang (e) {
    if (e.target.checked){
      i18n.changeLanguage("es")
      setData(false);
      setCV(CVes);
    }else{
      i18n.changeLanguage("en")
      setData(true);
      setCV(CVen);
      
    }
    console.log(selectCV)
  } 
    
   return (
    <header>
      <div className='check-general'>
        <span className='en'>Ingles</span>
          <label className="switch">
            <input type="checkbox"  className='check-only'onChange={changeLang}/>
            <span className="slider"></span>
          </label>
        <span className='esp'>Español</span>
      </div>
      <div className='container header__container'>
        <h5>{text("header.say-hello")}</h5>
        <h1>Gaston Carreiras</h1>
        <h5 className='text-light'>Full Stack Developer</h5>
        <CTA data={selectCV}/>
        <HeaderSocials/>
        <div className='me'>
          <img src={ME1} alt='me' />
        </div>
        <a href='#contact' className='scroll__down'>{text("scroll.down")}</a>
      </div>

    </header>
  )
}

export default Header