import React from 'react';
import CVen from '../../assets/CV Gaston Carreiras English.pdf';
import CVes from '../../assets/CV Gaston Carreiras Español.pdf';
import { useTranslation } from "react-i18next"
import { useState } from 'react';



const CTA = (selectCV) => {


  const [ text ] = useTranslation('global')
  
  return (
    <div className='cta'>

        <a href= {selectCV.data} download className='btn'>{text('header-cv.cv')}</a>
        <a href='#contact' className='btn btn-primary'>{text('header-cv.talk')}</a>
        
    </div>
  )
}

export default CTA